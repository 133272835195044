<template>
	<div v-if="text" class="break-words">
		<template v-for="(line, index) in lines">
			<h5 v-if="line.type === 'headline'" :key="`${index}-heading`">{{ line.value }}</h5>
			<p
				v-else-if="line.type === 'paragraph'"
				:key="index"
				:class="{
					'mb-0': nextLineType(index) === 'paragraph' || nextLineType(index) === 'emptyLine'
				}"
			>
				<template v-for="(item, itemIndex) in line.values">
					<span
						v-if="item.type === 'text'"
						:key="`line-${index}-item-${itemIndex}`"
						:class="{
							'font-bold': item.formatting.includes('bold'),
							italic: item.formatting.includes('italic'),
							underline: item.formatting.includes('underline')
						}"
					>
						{{ item.value }}
					</span>
					<span
						v-else-if="item.type === 'apply'"
						:key="itemIndex"
						role="link"
						class="cursor-pointer text-color-main hover:underline hover:text-color-main-hover"
						@click="openInvisibleLink(linkToApply)"
						>{{ item.value }}</span
					>
					<HokLink
						v-else-if="item.type === 'email'"
						:key="`${itemIndex}-email`"
						:to="`mailto:${item.value}`"
						class="hover:underline hover:text-color-main-hover"
						>{{ item.value }}</HokLink
					>
				</template>
			</p>
			<ul
				v-else-if="line.type === 'list'"
				:key="`${index}-ul`"
				class="list-outside list-disc pl-5 mb-4"
			>
				<li v-for="(listItem, listIndex) in line.values" :key="listIndex">
					<template v-for="(item, itemIndex) in listItem">
						<span
							v-if="item.type === 'text'"
							:key="`line-${index}-list-${listIndex}-item-${itemIndex}`"
							:class="{
								'font-bold': item.formatting.includes('bold'),
								italic: item.formatting.includes('italic'),
								underline: item.formatting.includes('underline')
							}"
						>
							{{ item.value }}
						</span>
						<span
							v-else-if="item.type === 'apply'"
							:key="itemIndex"
							role="link"
							class="cursor-pointer text-color-main hover:underline hover:text-color-main-hover"
							@click="openInvisibleLink(linkToApply)"
							>{{ item.value }}</span
						>
						<HokLink
							v-else-if="item.type === 'email'"
							:key="`${itemIndex}-email`"
							:to="`mailto:${item.value}`"
							class="hover:underline hover:text-color-main-hover"
							>{{ item.value }}</HokLink
						>
					</template>
				</li>
			</ul>
			<br v-else-if="line.type === 'emptyLine'" :key="`${index}-br`" />
		</template>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { beautifyText } from '../helpers/beautifyText';

export default defineComponent({
	name: 'BeautifyText',
	computed: {
		lines() {
			return beautifyText(this.text, true);
		}
	},
	methods: {
		nextLineType(index: number) {
			return this.lines[index + 1]?.type;
		},
		openInvisibleLink(to: string) {
			window.location.href = to;
		}
	},
	props: {
		text: { type: String, required: true },
		linkToApply: { type: String, default: '#' }
	}
});
</script>
